<template>
  <div
    class="full-area vertically-centered bg-image screen-outer-wrapper screen-component-wrapper-tag-29102021"
    :class="{
      'full-area-desktop': fullWidth || data.fields.darkBackground || data.fields.darkImageBackground,
      'scrollable': data.fields.isScrollable,
      'animated-background': data.fields.darkBackground || data.fields.darkImageBackground
    }"
    :style="{
      backgroundImage: bgImage ? `url(${bgImage}) !important` : ''
    }"
    v-if="data.fields"
  >
    <QuestionHeader
      :data="data"
      :no-question-title="true"
      :no-title="true"
    ></QuestionHeader>

    <div class="header-hint" v-if="data.fields.headerHint || data.fields.youTubeHeaderVideo">
      {{data.fields.headerHint}} <span class="header-hint-video-action" @click="startVideo(data.fields.youTubeHeaderVideo)">(ver video)</span>

      <div class="overlay-close-button-wrapper" v-if="overlay" @click="quitOverlay()">
        <Icons class="icon" icon="close" size="48" color="#fff"></Icons>
      </div>
      <div class="video-overlay" v-if="overlay" @click="quitOverlay()">
        <div class="yt-embed yt-embed-vertical" v-if="data.fields.youTubeHeaderVideo" v-html="getVideoEmbed(data.fields.youTubeHeaderVideo, 0)"></div>
      </div>

    </div>

    <div class="yet-another-wrapper" :class="{'allow-large-image': data.fields.largeImage}">
      <audio ref="audioOnCta" style="display: none;" v-if="data.fields.audioOnCta">
        <source
          :src="data.fields.audioOnCta.fields.file.url"
          :type="data.fields.audioOnCta.fields.file.contentType"
        />
      </audio>
      <audio ref="audioOnEnter" autoplay="true" style="display: none;" v-if="data.fields.audioOnEnter">
        <source
          :src="data.fields.audioOnEnter.fields.file.url"
          :type="data.fields.audioOnEnter.fields.file.contentType"
        />
      </audio>
      <div class="logo"
        v-if="data.fields.logo"
      >
        <img :src="data.fields.logo.fields.file.url">
      </div>
      <div class="yt-embed" :class="{ 'is-vertical': data.fields.verticalVideo }" v-if="data.fields.youTubeVideo" v-html="getVideoEmbed(data.fields.youTubeVideo, 0)">
      </div>
      <div class="swipeable-area"
        v-if="routeName === 'page'"
        v-touch:swipe.left="swipeLeftHandler"
        v-touch:swipe.right="swipeRightHandler"
      ></div>
      <div class="desktop-control delay-entry"
        v-if="arrows && !data.fields.isScrollable && routeName === 'page'"
      >
        <div
          @click="previous()"
        >
          <Icons
            class="icon"
            icon="chevron-left"
            size="96"
            :color="data.fields.darkBackground || data.fields.darkImageBackground ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.4)'"
          ></Icons>
        </div>
        <div
          @click="next()"
        >
          <Icons
            class="icon"
            icon="chevron-right"
            size="96"
            :color="data.fields.darkBackground || data.fields.darkImageBackground ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.4)'"
          ></Icons>
        </div>
      </div>
      <h2 v-if="data.fields.questionTitle">{{data.fields.questionTitle}}</h2>
      <vue-markdown class="html-content first-content-block delay-entry">{{data.fields.text}}</vue-markdown>

      <vue-markdown class="html-content left-centered-content delay-entry">{{mdToHTML(data.fields.richText)}}</vue-markdown>

      <div class="html-content dynamic-content" v-if="data.fields.dynamicContent" v-html="useGlobalVars(data.fields.dynamicContent)">
      </div>


      <div class="fb-connect-wrapper" v-if="data.fields && data.fields.facebookScope">
        <el-button class="fb-login-cta" @click="loginFB()">
          <Icons
            class="icon"
            icon="facebook"
            size="32"
            :color="'rgba(255,255,255,0.9)'"
          ></Icons>
          <span>Conéctate con Facebook</span>
        </el-button>

        <p>Podrás confirmar la información ingresada a continuación.</p>
      </div>

      <p
        class="padded"
      >
        <el-input
          class="nickname-field"
          placeholder="Escoge tu nickname para participar" v-model="nickname" v-if="data.fields.nickname"
        ></el-input>
        <el-button
          round
          type="danger"
          @click="ctaAction()"
          class="align-right delay-entry"
          v-if="data.fields.callToAction"
        >{{getCallToAction()}}</el-button>
        <a
          class="default-logo-wrapper"
          href="https://www.sensata.io"
          v-if="!data.fields.callToAction && data.fields.action !== 'Dismiss' && !data.fields.autoDismiss"
        >
          <SensataSplash></SensataSplash>
        </a>
      </p>
      <p class="small" v-html="data.fields.remarks"></p>

      <div v-if="data.fields.surveyRedirect && Object.keys(data.fields.surveyRedirect).length > 6" class="survey-redirect-wrapper">
        <el-select
          v-model="surveyRedirect"
          placeholder="--"
          class="date-year"
          key="date-year"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="(option, key) in data.fields.surveyRedirect"
            :key="key"
            :label="option"
            :value="key"
          ></el-option>
        </el-select>
      </div>
      <div v-if="data.fields.surveyRedirect && Object.keys(data.fields.surveyRedirect).length <= 6" class="survey-redirect-wrapper-grid">
        <div class="grid-wrapper delay-entry">
          <div
            :span="12"
            v-for="(item, key) in data.fields.surveyRedirect"
            class="option-box"
            :key="Math.random() + rand"
            :class="{'standout': surveyRedirect === key}"
            @click="surveyRedirect = key"
          >{{item}}<i class="el-icon-check icon-inside"></i></div>
        </div>
      </div>
    </div>

    <SkipQuestionButton
      :location="'locate-bottom'"
      :show-back-button="data.fields.showBackButton"
      :is-mandatory="true"
      v-if="data.fields.showBackButton"
      v-on:previous="previousQuestion()"
    ></SkipQuestionButton>
  </div>
</template>

<script>
const removeMd = require('remove-markdown')
import VueMarkdown from 'vue-markdown'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export default {
  name: 'Screen',

  transition: 'page',

  props: [ 'data', 'fullWidth' ],

  components: {
    VueMarkdown,
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SensataSplash: () => import('Components/base/SensataSplash'),
    Icons: () => import('Components/base/Icons')
  },

  data () {
    return {
      overlay: false,
      isPlaying: false,
      arrows: false,
      nickname: null,
      accessToken: '',
      surveyRedirect: '',
      form: {
        identifier: this.data.fields.title,
        type: 'screen',
        numberOfOptions: 1,
        question: removeMd(this.data.fields.text),
        options: this.data.fields.callToAction || this.data.fields.conditionalCTA,
        id: this.data.sys.id,
        selected: null,
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      }
    }
  },

  computed: {
    bgImage () {
      var bgImage = this.data.fields.backgroundImage ? this.data.fields.backgroundImage.fields.file.url : ''
      if (this.data.fields.conditionalCTA) {
        var keys = Object.keys(this.data.fields.conditionalCTA)
        if (keys[0] in this.$store.state.completedSurveys && this.data.fields.conditionalBackgroundImage) {
          bgImage = this.data.fields.conditionalBackgroundImage.fields.file.url
        }
      }
      return bgImage
    },
    routeName () {
      return this.$route.name
    }
  },

  methods: {
    getCallToAction () {
      if (this.data.fields.conditionalCTA) {
        var keys = Object.keys(this.data.fields.conditionalCTA)
        if (keys[0] in this.$store.state.completedSurveys) {
          return this.data.fields.conditionalCTA[keys[0]]
        } else {
          return this.data.fields.callToAction
        }
      } else {
        return this.data.fields.callToAction
      }
    },
    startVideo (videoID) {
      this.overlay = true
      this.getVideoEmbed(videoID)
    },
    quitOverlay () {
      this.overlay = false
    },
    mdToHTML (markdown) {
      return documentToHtmlString(markdown)
    },
    useGlobalVars (content) {
      var c = content.replace(/var.x/g, this.$store.state.globalVars.x)

      var part = c.substring(
        c.lastIndexOf('${') + 2,
        c.lastIndexOf('}$')
      )
      return c.replace('${' + part + '}$', Number(eval(part).toFixed(1)).toLocaleString())
    },
    previousQuestion () {
      this.$emit('previous')
    },
    loadFB () {
      if (typeof window !== "undefined" && window.FB) {
        window.FB.init({
          xfbml: true,
          version: 'v3.0',
          appId  : process.env.VUE_APP_FACEBOOK_APP_ID,
        })
      }
    },
    loginFB () {
      var fb = this.$store.state.fbData
      if (fb && fb.id) {
        this.next()
        return
      } else {
        window.FB.login(response => {
          // TODO check all the other options
          if (response.status === 'connected') {
            this.$store.commit('STORE_FACEBOOK_RESPONSE', { response: response })
            this.accessToken = response.authResponse.accessToken
            window.FB.api('/me', {fields: 'name, email', accessToken: this.accessToken}, newResponse => {
              this.$store.commit('STORE_FACEBOOK_DATA', { response: newResponse })
              this.connected = true
              setTimeout(() => {
                this.next()
              }, 50)
            })
          } 
        }, {scope: 'public_profile,email'})
      }
    },
    playAudio (reference) {
      if (this.$refs[reference]) {
        if (!this.$refs[reference].paused) {
          this.$refs[reference].pause()
        } else {
          this.$refs[reference].play()
        }
      }
    },
    getVideoEmbed (ytID, ytStartTime) {
      return `
        <iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/${ytID}?start=${ytStartTime}&autoplay=0&showinfo=0&iv_load_policy=3&modestbranding=0&origin&rel=0&controls=1&autohide=1${process.env.VUE_APP_BASE_URL}" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>      `
    },
    swipeRightHandler () {
      this.previous()
    },
    swipeLeftHandler () {
      this.next()
    },
    ctaAction () {
      if (this.data.fields.randomFormula) {
        this.randomizeFormulas()
        return
      }

      this.playAudio('audioOnCta')
      if (this.data.fields.action === 'GoToURL' && this.data.fields.goToUrl) {
        if (this.data.fields.goToUrl.indexOf('/') === 0) {
          this.$router.push(this.data.fields.goToUrl)
        } else {
          window.location.href = this.data.fields.goToUrl
        }
      }
      if (this.data.fields && this.data.fields.nickname) {
        if (this.nickname) {
          this.form.selected = [this.nickname]
        } else {
          this.form.selected = [`Usuario ${Math.floor(Math.random() * 10000)}`]
        }
      } else {
        this.form.selected = ['next']
      }
    },
    next () {
      this.arrows = false
      this.form.timestamps.finish = new Date()
      this.form.skip = this.data.fields.skip > 0 ? this.data.fields.skip : 0

      if (this.data.fields.randomSkip) {
        var items = this.data.fields.randomSkip.split(',')
        this.form.skip = items[items.length * Math.random() | 0]
      }

      setTimeout(() => {
        if (this.data.fields.action) {
          this.$emit(this.data.fields.action.toLowerCase(), this.form)
          if (this.data.fields.action.toLowerCase() === 'gotourl' && this.data.fields.goToUrl) {
            this.$emit('goto', this.data.fields.goToUrl)
          }
        } else {
          this.$emit('next', this.form)
        }
      }, this.data.fields.audioOnCta ? 1500 : 100)
    },
    previous () {
      this.arrows = false
      this.form.timestamps.finish = new Date()
      this.$emit('previous', this.form)
    },
    skipQuestion () {
      this.form.selected = ['S99']
      this.next()
    },
    validInput (value) {
      // Add simple validation: if it exists, it is valid.
      if (value) {
        this.form.timestamps.valid = new Date()
        this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
        return true
      } else {
        return false
      }
    },
    randomizeFormulas () {
      if (this.data.fields.randomFormula) {
        // this should select a random formula from the list
        let randomizedFormula = this.data.fields.randomFormula[Math.floor(Math.random() * this.data.fields.randomFormula.length)]
        this.form.selected = [randomizedFormula]

        return this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
          formula: this.data.fields.randomFormula,
          selected: this.form.selected,
          options: this.data.fields.randomFormula,
          maxAnswers: 1,
        }).then(scores => {
          this.form.weighted = scores.weighted.slice(0)
          this.form.weightedMin = scores.weightedMin
          this.form.weightedMax = scores.weightedMax
          this.feedback = scores.feedback
          this.form.skip = scores.newSkip
          if (this.data.fields.randomSkip) {
            var items = this.data.fields.randomSkip.split(',')
            this.form.skip = items[items.length * Math.random() | 0]
          }
          this.next()
        }).catch(err => {
          console.log('err: ', err)
        })


      }
    },
  },

  mounted () {
    if (this.data.fields.requireSurvey && !(this.data.fields.requireSurvey in this.$store.state.completedSurveys)) {
      this.$store.dispatch('LOAD_NEW_SURVEY', { surveyId: this.data.fields.requireSurvey, force: true })
      this.$router.push({ name: 'survey', params: { sid: this.data.fields.requireSurvey, qid: 0 } })
      return
    }

    if (this.data.fields.requireSurvey && this.data.fields.requireSurvey in this.$store.state.completedSurveys) {
      this.$emit('next', this.form)
      return
    }

    this.loadFB()

    setTimeout(() => {
      this.arrows = true
    }, 300)

    // Play an entry audio if set
    if (this.$refs['audioOnEnter']) {
      this.playAudio('audioOnEnter')
    }

    if (this.data.fields.action === 'Dismiss') {
      var timer = process.env.VUE_APP_DISPLAY_DELAY_FACTOR * 3 || 15000
      setTimeout(() => {
        var redirectTo = localStorage.getItem('redirect_to_display')

        if (redirectTo) {
          localStorage.removeItem('redirect_to_display')
          return this.$router.push(redirectTo)
        }
        /*
        if (did) {
          localStorage.removeItem('redirect_to_display')
          return this.$router.push(`/display/${did}`)
        }
        */
      }, timer)
    }

    if (this.data.fields.autoDismiss) {
      setTimeout(() => {
        this.$emit('next', this.form)
      }, 1500)
    }
    if (this.data.fields.autoSkip) {
      // if randomFormula, then store the profile code
      if (this.data.fields.randomFormula) {
        this.randomizeFormulas()
        return
      }
      setTimeout(() => {
        this.skipQuestion()
      }, 1)
    }
  },

  watch: {
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal)) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    },
    'surveyRedirect': function (newVal) {
      setTimeout(() => {
      this.$store.dispatch('LOAD_NEW_SURVEY', { surveyId: newVal })
        .then(() => {
          // TODO: this does not work exactly as planned as the params are already in memory
          //this.$router.push(`/s/${newVal}/0?utm_campaign=live&utm_medium=referral&utm_source=${this.$route.params.sid}`)
          this.$router.push({
            name: 'survey',
            params: {
              sid: newVal,
              qid: 0
            }
          })
        })
      }, 50)
    }
  }
}
</script>

<style scoped lang="scss">
.survey-redirect-wrapper {
  .el-select {
    width: 100%;
  }
}
.survey-redirect-wrapper-grid {
  max-width: 90%;
  margin: auto;
  .option-box {
    min-height: 60px;
    background: #f0f0f0;
    padding: 6% 3%;
    margin: 1%;
    border-radius: 5px;
    width: 42%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 1.1;
    @include breakpointHeight($square) {
      font-size: 18px;
      min-height: 40px;
    }
    &.standout {
      background-color: $color-emphasis-alt;
    }
  }
}

.header-hint {
  color: #333333;
  position: fixed;
  top: 0;
  right: 0;
  text-align: right;
  z-index: 1000000;
  font-style: italic;
  padding: 5%;
  .header-hint-video-action {
    text-decoration: underline;
    color: $color-emphasis;
  }
}
.video-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(black, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay-close-button-wrapper,
.repeat-button-wrapper,
.remarks-wrapper {
  position: fixed;
  top: 2px;
  right: 10px;
  z-index: 1001;
  opacity: 0.7;
  color: white;
  &:hover {
    cursor: pointer;
  }
}

.dynamic-content {
  margin-bottom: 20px;
}
.survey-redirect-wrapper {
  max-width: 90%;
  margin: auto;
}
.fb-connect-wrapper {
  padding: 0 5%;
  margin-bottom: 2em;
}

.yt-embed {
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  &.is-vertical {
    padding-bottom: 140%;
  }
}
.yt-embed-vertical {
  height: 50vh;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
}

.default-logo-wrapper {
  max-width: 40%;
  display: block;
  margin: auto;
}
.bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.full-area-desktop {
  max-width: 100vw;
}

.full-area.scrollable {
  max-height: none !important;
  display: block;
  overflow-y: scroll;

  @include breakpoint($tablet) {
    max-width: 800px;
  }
  .first-content-block {
    margin-top: 3em;
  }
}

.full-area.padded {
  padding: 2% 5%;

  @include breakpoint($desktop) {
    padding: 5% 10%;
  }
  .yet-another-wrapper {
    max-width: 800px;
    margin: auto;
    position: relative;
    z-index: 1;
    width: 100%;

    .swipeable-area {
      position: fixed;
      height: 70vh;
      width: 80vw;
      margin: 0 auto;
      top: 0;
      left: 10vw;
      background: transparent;
      z-index: 0;
    }
    .button {
      position: relative;
      z-index: 20;
    }
    .smartphone-next {
      position: fixed;
      bottom: 0.5em;
      right: 0em;
      z-index: 20;

      @include breakpoint($desktop) {
        display: none;
      }
      .icon {
        position: relative;
        z-index: 30;
      }
    }
    .desktop-control {
      display: none;

      @include breakpoint($desktop) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
      }
      .icon {
        transition: transform 0.2s ease-in-out;
        &:hover {
          transform: scale(1.1);
          cursor: pointer;
        }
      }
    }
    .logo {
      width: 100%;
      margin-bottom: 1.5em;
      margin-top: 1em;
      border-bottom: 1px solid $gray-light;
      padding-bottom: 1.5em;
      text-align: left;
      img {
        max-width: 150px;
      }
    }
  }
}

.full-area {
  max-height: 100vh;
  .yet-another-wrapper {
    max-width: 800px;
    margin: auto;
    position: relative;
    z-index: 1;
    width: 100%;

    @include breakpoint($tablet) {
      max-width: 600px;
      .html-content {
        h3 {
          font-size: 22px !important;
        }
        p {
          font-size: 18px !important;
        }
      }
    }

    .swipeable-area {
      position: fixed;
      height: 70vh;
      width: 80vw;
      margin: 0 auto;
      top: 0;
      left: 10vw;
      background: transparent;
      z-index: 0;
    }
    .button {
      position: relative;
      z-index: 20;
    }
  }
  .logo {
    width: 100%;
    margin-bottom: 1.5em;
    margin-top: 1em;
    border-bottom: 1px solid $gray-light;
    padding-bottom: 1.5em;
    text-align: left;
    img {
      max-width: 150px;
    }
  }
  .desktop-control {
    display: none;

    @include breakpoint($desktop) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
    }
    .icon {
      transition: transform 0.2s ease-in-out;
      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
  }
  .smartphone-next {
    position: fixed;
    bottom: 2.5em;
    right: 0em;
    z-index: 20;

    @include breakpoint($desktop) {
      display: none;
    }
    .icon {
      position: relative;
      z-index: 30;
    }
  }
}

.animated-background {
  background: linear-gradient(221deg, $color-wrapper-emphasis, $color-wrapper-emphasis-lighter);
  background-size: cover;
  -webkit-background-size: cover;
  background-position: bottom center fixed !important;

  color: white;

  .html-content {
    blockquote {
      p {
        color: $color-wrapper-emphasis-lighter;
      }
    }
  }
  .el-button {
    background-color: rgba(white, 0.9);
    border: 2px solid white;
    box-shadow: 0px 0px 100px rgba(black, 0.3);
    font-size: 18px;
    border-radius: 25px;
    color: rgba($text-main, 0.8);    
  }
}

.nickname-field {
  margin-bottom: 1.5em;
  width: 90%;
  @include breakpoint($tablet) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
</style>
<style lang="scss">
.yt-embed iframe,
.yt-embed object,
.yt-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.nickname-field {
  .el-input__inner {
    border: 1px solid $color-emphasis;
    text-align: center !important;
  }
  .el-input__inner:focus {
    border-color: rgba($gray-medium, 0.4);
  }
}

.yet-another-wrapper {
  .html-content {
    padding-left: 5%;
    padding-right: 5%;
    h1 {
      font-size: 1.8em;
      @include breakpointHeight($square) {
        font-size: 21px;
      }
    }

    p {
      @include breakpointHeight($square) {
        font-size: 15px;
      }
    }

    img {
      //max-width: 70% !important;
      width: 112% !important;
      margin: auto !important;
      margin-bottom: 1em;
      display: block;
      @include breakpoint($tablet) {
        max-width: 50vw !important;
        margin: auto !important;
      }
      @include breakpoint($tablet) {
        max-width: 300px !important;
      }
    }
    ul li {
      text-align: left;
    }
  }
  &.allow-large-image {
    .html-content {
      img {
        max-width: none !important;
        width: 95% !important;
        margin-bottom: 10px !important;
        @include breakpointHeight($square) {
          max-width: 70% !important;
        }
        @include breakpoint($micro) {
          max-width: 80% !important;
        }
      }
    }
  }
}

.screen-outer-wrapper {
  .fb-login-cta {
    background: #3b5998;
    border-color: #3b5998;
    color: white;
    width: 100%;
    padding: 6px 10px;
    margin-bottom: 2em;
    span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &:hover,
    &:focus {
      background: rgba(#3b5998, 0.9);
      border-color: rgba(#3b5998, 0.9);
      color: white;
    }
  }
}

.slider-value.is-currency {
  margin-bottom: 0;
  font-size: 2.5em;
  font-weight: bold;
  color: $color-emphasis-blue;
}
</style>
<style lang="scss">
.year-type-wrapper {
  input.el-input__inner {
    text-align: center !important;
  }
}
.el-select {
  position: relative !important;
}
.el-select-dropdown {
  position: absolute !important;
  width: 100% !important;
  margin-top: 60px;
  min-width: 150px !important;
  max-width: 400px;
}

.el-select-dropdown__item {
  font-size: 17px !important;
  &.selected {
    color: $color-emphasis;
  }
}
.el-select {
  width: 90vw;
  @include breakpoint($tablet) {
    width: 100%;
  }
  .el-input.is-focus {
    .el-input__inner {
      border-color: $gray-medium !important;
    }
  }
  .el-input {
    .el-input__inner {
      &:focus {
        @include breakpoint($tablet) {
          border-color: $gray-medium !important;
        }
      }
    }
  }
}
.el-select-dropdown__wrap {
  max-height: 30vh !important;
}
.class-iOS {
  .el-select-dropdown__wrap {
    max-height: 220px !important;  
  }
}
.el-textarea {
  width: 90vw;  
}

.el-input__inner,
.el-textarea__inner {
  border-color: $color-emphasis !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: $color-emphasis !important;
  background-color: $color-emphasis !important;  
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: $color-emphasis !important;
}
.el-checkbox__inner{
  &:hover {
    border-color: $color-emphasis !important;
  }
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: $gray-medium !important;
}
.el-checkbox__label {
  font-size: 16px;
  line-height: normal;
}
.el-drawer__body {
  overflow-y: scroll;
  @include breakpoint($desktop) {
    font-size: 0.8em;
  }
}
.yet-another-wrapper {
  .html-content {
    &.left-centered-content {
      p {
        text-align: left !important;
      }
    }
  }
}

.full-area.scrollable.animated-background {
  .el-button--danger {
    background-color: rgba(white, 0.9);
    border: white;
    color: $color-wrapper-emphasis;
    font-size: 20px;
  }
  p.padded {
    margin-bottom: 0 !important;
    padding-bottom: 20px !important;
  }  
}

.yt-embed iframe,
.yt-embed object,
.yt-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
